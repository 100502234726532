(function ($) {
   var youtube = document.querySelectorAll(".youtube-box-iframe");
   for (var i = 0; i < youtube.length; i++) {
      // add the code here
      var source = "https://img.youtube.com/vi/" + youtube[i].dataset.embed + "/maxresdefault.jpg";
      // Load the image asynchronously
      var image = new Image();
      image.src = source;
      image.addEventListener("load", function () {
         youtube[i].appendChild(image);
      }(i));

      youtube[i].addEventListener("click", function () {

         const url = `https://www.youtube.com/watch?v=${this.dataset.embed}`;
         window.open(url,'_blank');
         // var iframe = document.createElement("iframe");

         // iframe.setAttribute("frameborder", "0");
         // iframe.setAttribute("allowfullscreen", "");
         // iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.embed + "?rel=0&showinfo=0&autoplay=1");

         // this.innerHTML = "";
         // this.appendChild(iframe);
      });
   }
}(jQuery))