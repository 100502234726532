window.onload = function() {
    window.addEventListener('scroll', (event) => {
        checkTopScrollWindow()
    });

    checkTopScrollWindow();
    acoesMobile();
    findInitSlider();

    window.addEventListener('resize', (event) => {
        acoesMobile()
    });

    if (jQuery('.thumb-hero').length) {

        jQuery('.thumb-hero').click(function() {
            fadeOutBanners()
            const id = jQuery(this).attr('id').replace('thumb-', '');
            jQuery(`#banner-${id}`).removeClass('fadeOut');
            jQuery(`#banner-${id}`).addClass('fadeIn')
            jQuery(`#thumb-${id}`).addClass('active')
        });
    }

    if (jQuery('.owl-carousel-reviews').length) {
        jQuery('.owl-carousel-reviews').owlCarousel({
            loop: false,
            nav: false,
            responsive: {
                0: {
                    items: 1,
                    margin: 30,
                },
                600: {
                    items: 3,
                    margin: 30,
                },
                1000: {
                    items: 3,
                    margin: 60,
                }
            }
        })
    }

    if (jQuery('.load-more').length) {
        jQuery('.load-more').click((e) => {
            e.preventDefault();
            const dataSource = jQuery('.load-more').attr('data-source');
            jQuery('.loading').removeClass('hide')
            jQuery('.load-more').addClass('hide')
            const data = {
                'action': 'loadmore',
                'query': posts,
                'page': current_page
            };

            jQuery.ajax({
                url: scripts_main_params.ajaxurl,
                data: data,
                method: 'POST',
                dataType: 'html',
                success: function(result) {
                    // console.log('result', result);
                    if (result) {
                        current_page++;
                        jQuery('.loading').addClass('hide')
                        jQuery('.load-more').removeClass('hide')
                        jQuery('.posts-grid').append(result)

                        if (current_page == max_page)
                            jQuery('.load-more').remove();

                    } else {
                        jQuery('.load-more').remove();
                    }
                }
            })
        })
    }
}

function findInitSlider() {
    jQuery('div[id*=banner-]').each(function() {
        if (jQuery(this).hasClass('fadeIn')) {
            const id = jQuery(this).attr('id').replace('banner-', '');
            jQuery(`#thumb-${id}`).addClass('active')
        }
    })
}

function fadeOutBanners() {
    jQuery('div[id*=banner-]').each(function() {
        jQuery(this).addClass('fadeOut');
        jQuery(this).removeClass('fadeIn');
    })
    jQuery('li[id*=thumb-]').each(function() {
        jQuery(this).removeClass('active');
    })
}

function acoesMobile() {
    // const isHeaderInterno = jQuery('.header').hasClass('header-intern');
    if (isMobile()) {
        jQuery('.header').addClass('header-fixed');
    } else {
        jQuery('.header').removeClass('header-fixed');
    }
}

function checkTopScrollWindow() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    // const isHeaderInterno = jQuery('.header').hasClass('header-intern');
    if (!isMobile()) {
        if (top > 100) {
            jQuery('.header').addClass('header-fixed');
        } else {
            jQuery('.header').removeClass('header-fixed');
        }
    }
};

function isMobile() {
    const doc = document.documentElement;
    const width = (window.screen.width || doc.screen.width);

    if (width <= 1100) {
        return true;
    }

    return false;
};